const formValidation = {
    //用户名||登陆前缀||字典代码
    loginPrefix: (rule, value, callback) => {
        var reg = /^[^!@#$%^*_\-+={}|\\:;'".,/?\s]([^!@#$%^*+={}|\\:;'".,/?\s]{0,32}[^!@#$%^*_\-+={}|\\:;'".,/?\s])?$/;
        if (!reg.test(value)) {
            callback(new Error("格式不正确"));
        } else {
            callback();
        }
    },
    //用户名||登陆前缀||字典代码
    username: (rule, value, callback) => {
        if (!value) callback();
        var reg = /^[^!@#$%^*_\-+={}|\\:;'".,/?\s]([^!@#$%^*+={}|\\:;'".,/?\s]{0,40}[^!@#$%^*_\-+={}|\\:;'".,/?\s])?$/;
        if (!reg.test(value)) {
            callback(new Error("格式不正确"));
        } else {
            callback();
        }
    },
    //字典代码
    code: (rule, value, callback) => {
        var reg = /^[a-zA-Z]{1}([a-zA-Z0-9]|[_-]){0,31}$/;
        if (!reg.test(value)) {
            callback(new Error("格式不正确"));
        } else {
            callback();
        }
    },
    //手机号
    mobile: (rule, value, callback) => {
        if (!/^1[3456789]\d{9}$/.test(value)) {
            callback(new Error("手机号格式不正确"));
        } else {
            callback();
        }
    },
    //页面名称||菜单名称||功能名称||接口名称||字典名称
    pagename: (rule, value, callback) => {
        var reg = /^[a-zA-Z\u4e00-\u9fa5][\w\u4e00-\u9fa5 -]{0,31}/;
        if (!reg.test(value)) {
            callback(new Error("格式不正确"));
        } else {
            callback();
        }
    },
    menusName: (rule, value, callback) => {
        // var reg = /^[\u0391-\uFFE5A-Za-z][ \w\u0391-\uFFE5]+$/;
        var reg = /^[a-zA-Z\u4e00-\u9fa5]+$/
        if (!reg.test(value)) {
            callback(new Error("格式不正确"));
        } else {
            callback();
        }
    },
    //vue路由
    vuename: (rule, value, callback) => {
        var reg = /^[a-zA-Z]{1}([a-zA-Z0-9]|[_-]){0,32}$/;
        if (!reg.test(value)) {
            callback(new Error("格式不正确"));
        } else {
            callback();
        }
    },
    //请求路径
    path: (rule, value, callback) => {
        if (value[0] != "/") {
            callback(new Error("格式不正确"));
        } else {
            callback();
        }
    },
    //字典值中的中文值
    chine: (rule, value, callback) => {
        // ^[\u4E00-\u9FA50-9_]+$
        // var reg = /^[0-9\u4e00-\u9fa5]/;
        var reg = /^[\u4E00-\u9FA50-9_]+$/
        if (!reg.test(value)) {
            callback(new Error("格式不正确"));
        } else {
            callback();
        }
    },
    //字典值中数字代码
    numcode: (rule, value, callback) => {
        if (value > 127 && value >= 0) {
            callback(new Error("数字代码取值范围是0-127"));
        } else {
            callback();
        }
    },
    englishName: (rule, value, callback) => {
        if (value == '' || value == null) {
            callback();
        }
        var reg = /^[a-zA-Z]{1}([a-zA-Z0-9]|[_-]){0,32}$/;
        if (!reg.test(value)) {
            callback(new Error("格式不正确"));
        } else {
            callback();
        }
    },
    checkJson: (rule, value, callback) => {
        if (value == '' || value == null) {
            callback()
            return
        }
        try {
            JSON.parse(value);
        } catch (e) {
            console.log("json 格式错误")
            callback(new Error('json格式错误'));
        }
        callback()
    },
    //版本号
    versionNum: (rule, value, callback) => {
        var reg = /^[1-9]\d?(\.(0|[1-9]\d?)){2}$/;
        if (!reg.test(value)) {
            callback(new Error("格式不正确"));
        } else {
            callback();
        }
    },



    //接口版本验证
    checkapiVersion: (rule, value, callback) => {
        var reg = /^[0-9]{1,5}( *, *[0-9]{1,5})*$/;
        if (!reg.test(value)) {
            callback(new Error("格式不正确"));
        } else {
            callback();
        }
    },

    checkresourceVersion: (rule, value, callback) => {
        var reg = /^[0-9]{1,5}( *, *[0-9]{1,5})*$/;
        if (!reg.test(value)) {
            callback(new Error("格式不正确"));
        } else {
            callback();
        }
    },


    // //下载地址验证
    checkapkUrl: (rule, value, callback) => {
        if (value == '' || value == null) callback();
        var reg = /(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?/;
        if (!reg.test(value)) {
            callback(new Error("格式不正确"));
        } else {
            callback();
        }
    },
    
    //APK包大小（kb）
    checkapkSize: (rule, value, callback) => {
        if (value == '' || value == null) callback();
        var reg =  /^\d+$|^\d+[.]?\d+$/;
        if (!reg.test(value)) {
            callback(new Error("格式不正确"));
        } else {
            callback();
        }
    },
}
export default formValidation